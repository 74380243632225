<template>
  <div
    class="white primary--text font-weight-black pt-4 pb-4 pl-8 pr-8 d-flex align-center rounded-lg"
  >
    <div class="info--number">NO.</div>
    <div class="info--name">NOMBRE</div>
    <div class="info--description">DESCRIPCIÓN</div>
    <div class="info--speed">VELOCIDAD</div>
    <div class="info--price">PRECIO</div>
    <div class="info--status">ESTATUS</div>
    <div class="info--actions">ACCIONES</div>
  </div>
</template>

<script>
export default {
  name: "InternetPlansTableHead",
};
</script>

<style lang="scss" scoped>
</style>